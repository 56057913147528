/* Navbar.css */

.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #000000;
  color: white;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
}

.brand-logo {
  filter: invert(100);
  height: 40px;
  width: 70px;
}

.navbar-logo h1 {
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
}

.navbar-links {
  display: flex;
  gap: 3.5rem;
  align-items: center;
}

.navbar-links a,
.dropdown-toggle {
  font-size: 1.08rem;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
}

.navbar-links a:hover,
.dropdown-toggle:hover {
  color: #00aaff;
}

/* Dropdown */
.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  opacity: 0;
  width: 160px;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-menu a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.dropdown-menu a:hover {
  color: #00aaff;
}

/* Signin and Get Started */
.navbar-actions {
  display: flex;
  gap: 1rem;
}

.navbar-signin,
.navbar-getstarted {
  color: white;
  text-decoration: none;
  border: 1px solid white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.navbar-signin:hover,
.navbar-getstarted:hover {
  color: #00aaff;
  border-color: #00aaff;
}

/* .header-drawer-bg {
  background: linear-gradient(182deg, #000000, #081011, #0d2523, #081817, #000000);
} */

@keyframes waveAnimation {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.header-drawer-bg {
  background: linear-gradient(182deg, #000000, #131313, #000000, #000000, #000000);
  background-size: 200% 200%;
  animation: waveAnimation 8s linear infinite;
}


.navbar-bottom-radius {
  border-bottom: 1px solid #101010;
}

.sign-signup-btn {
  font-size: 22px;
  font-weight: 600;
}

.title-link {
  font-size: 1.0rem;
  font-weight: 600;
}

/* Responsive adjustments */

/* Tablets and small screens */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .navbar-logo h1 {
    font-size: 1.3rem;
  }

  .navbar-links {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 0.5rem;
  }

  .navbar-links a,
  .dropdown-toggle {
    font-size: 1rem;
  }

  .navbar-actions {
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    align-items: flex-start;
    margin-top: 0.5rem;
  }
}

/* Mobile screens */
@media (max-width: 480px) {
  .navbar {
    padding: 0.5rem 1rem;
  }

  .brand-logo {
    height: 30px;
    width: 50px;
  }

  .navbar-logo h1 {
    font-size: 1.2rem;
  }

  .navbar-links {
    gap: 0.8rem;
  }

  .navbar-links a,
  .dropdown-toggle {
    font-size: 0.9rem;
  }

  .navbar-actions {
    gap: 0.5rem;
  }

  .navbar-signin,
  .navbar-getstarted {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }
}

.btn-border {
  border-radius: 6px;
  border: 1px solid #5c9c99;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.avatar-bg {
  background-color: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {

  /* Make avatar icon smaller on smaller screens */
  .avatar-bg {
    padding: 0.5rem;
  }
}

.sidebar-mt-6 {
  margin-top: 24px;
}

@media only screen and (max-width: 375px) and (max-height: 667px) {
  .sidebar-mt-6 {
    margin-top: 2px;
  }
}