/* .container {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  width: 80%;
} */

.playerContainer {
  display: flex;
  flex-direction: column;
  /* border-radius: 24px; */
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #2a2a2a;
  transition: transform 0.3s ease, z-index 0.3s ease;
  position: relative;
}

.videoPlayer {
  background: linear-gradient(45deg, #171717, #000000, #262626, transparent);
  height: 145px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
}

.controlsContainer {
  /* background: linear-gradient(45deg, #000000, #131e1d, #040605, transparent); */
  /* background: linear-gradient(45deg, #0a0a0a, #141414, #1f1f1f, #292929, #333333); */
  padding: 0 50px;
  z-index: 2;
  position: relative;
}

/* for collage collection */
.videoGridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
  margin-bottom: 40px;
}

.videoTitle {
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

/* for Library */
.videoGridContainerLibrary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 20px;
  margin-bottom: 190px;
}

@media (max-width: 768px) {
  .videoGridContainer {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px;
    margin-bottom: 80px;
  }

  .videoGridContainerLibrary {
    grid-template-columns: repeat(1, 1fr);
    padding: 14px;
    margin-bottom: 100px;
  }

  .controlsContainer {
    padding: 0 50px;
  }

  .videoPlayer {
    height: 120px;
  }

}

.playerContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  /* Place this background layer behind the video and controls */
  pointer-events: none;
  /* Allow clicks to pass through */
}

.controls {
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: space-between;
  color: #c4c4c4;
  margin-bottom: -8px;
}

.controlButton {
  border: none;
  background: none;
}

.controlButton:hover {
  cursor: pointer;
}

.duration {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  margin-bottom: 12px;
}

.timeline::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  background: #4c4c4c;
  border-radius: 5px;
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 14px;
  background: var(--button-bg-color);
  border: 2px solid #000;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -5px;
}

.timeline::-moz-range-track {
  width: 100%;
  height: 5px;
  background: #4c4c4c;
  border-radius: 5px;
}

.timeline::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: var(--button-bg-color);
  border: 2px solid #000;
  border-radius: 50%;
  cursor: pointer;
}

.timeline::-ms-track {
  width: 100%;
  height: 2px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.timeline::-ms-fill-lower {
  background: #4c4c4c;
  border-radius: 5px;
}

.timeline::-ms-fill-upper {
  background: #4c4c4c;
  border-radius: 5px;
}

.timeline::-ms-thumb {
  width: 5px;
  height: 5px;
  background: var(--button-bg-color);
  border: 1px solid #000;
  border-radius: 50%;
  cursor: pointer;
}