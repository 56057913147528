/* Container styling */
.banner .container {
    display: flex;
    flex-direction: column;
}

.banner .row {
    display: flex;
    flex-direction: column;
}


/* For screens wider than 768px */
@media (min-width: 768px) {
    .banner .row {
        flex-direction: row;
        align-items: center;
    }

    .banner .left-column {
        width: 64%;
    }

    .banner .right-column {
        width: 50%;
    }
}

/* Container styling */
.info .container {
    display: flex;
    flex-direction: column;
}

.info_h1 {
    font-weight: 500;
    font-size: 32px;
}

.mb {
    font-weight: 600;
    font-size: 45px;
}

.info .row {
    display: flex;
    flex-direction: column;
}

.info .right-column {
    line-height: 1.2;
    width: 100%;
    padding: 20px;
    color: #0f2445;
}

@media (min-width: 768px) {
    .info .row {
        flex-direction: row;
        justify-content: flex-end;

    }

    .info .right-column {
        width: 50%;
    }
}


.thirdsec .container {
    display: flex;
    flex-direction: column;
    padding: 0px 50px;
}

.thirdsec .row {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


@media (min-width: 768px) {
    .thirdsec .row {
        flex-direction: row;
        gap: 20px;
    }
}



.banner {
    background-image: url(../assets/partners_images/1.png);
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 40px;
}

.logo {
    width: 100px;
}

.rounded-lg.w-96.relative {
    background-color: #112121;
}

.about_section .row {
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
}

.about_section .img-box {
    position: relative;
    padding: 25px 0 0 25px;
}

.main h1 {
    margin-top: 14px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 3vw;
    font-family: "Archivo", sans-serif;
}

.main p {
    color: #fff;
    font-style: normal;
    text-wrap: balance;
}


/* ----------end main section----------- */

/*-------------- info sect-------------- */
.info {
    background-image: url(../assets/partners_images/2.png);
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
}

.info_h1 {
    color: #0f2445;

}

.info_h1 h2 {
    font-weight: 600;

}

.info_h1 h1 {
    font-weight: 600;
    font-size: 3rem;
    padding-bottom: 10px;
    font-family: "Archivo", sans-serif;
}

.info_h1 p {
    font-size: 1rem;

}

/*--------------end info sect-------------- */

/*--------- start third sect--------  */

.thirdsec {
    padding: 40px 0px 0px 0px;
    background-color: #112121 !important;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_box {
    background-color: #112121;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
}

.test {
    display: flex;
    justify-content: center;
    align-items: center;
}

.third_h1 h2 {
    color: #fff;
}

.third_h1 h1 {
    color: #5ae9bd;
    font-weight: bold;
    font-size: 3rem;
    font-family: "Archivo", sans-serif;
}

.btn1 {
    border-radius: 12px;
    background: url(../assets/partners_images/btn1.png) no-repeat;
    width: 300px;
    height: 70px;
    background-size: cover;
    display: block;
    color: #fff;
    border: #2ccba2 0.05px solid;
    margin: 10px;
    font-size: larger;

}


h1.third_h1 {
    color: #5ae9bd;
    font-weight: bold;
    font-size: 3rem;
    font-family: "Archivo", sans-serif;
}

.img1 {
    width: 180px;
}


.mb1 {
    font-size: 36px;
    color: white;
}

/*--------- end third sect--------  */

/*---------start why_u-----------*/

.why_us {
    background-image: url(../assets/partners_images/8.png);
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

ul.line {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    &:before {
        content: "";
        display: inline-block;
        width: 2px;
        background: #2dcaa0;
        position: absolute;
        left: 3px;
        top: 5px;
        height: calc(100% - 10px);
    }

    li {
        font-size: 20px;
        position: relative;
        padding-left: 15px;
        margin-bottom: 15px;

        &:before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #2dcaa0;
            position: absolute;
            left: 0;
            top: 5px;
            border-radius: 10px;
        }
    }
}

h2.fourth_h2 {
    font-weight: bold;
    font-size: 3rem;
    padding-bottom: 15px;
}

/*---------end why_u-----------*/

/*---------------- fifth sect-------- */
.fifth {
    background-image: url(../assets/partners_images/14.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 30px;
}

.sales {
    justify-content: center;
    align-items: center;
}

.sales h1 {
    font-weight: bold;
    font-family: "Archivo", sans-serif;
}

.p1 {
    text-align: center;
    font-size: 19px;
    margin-bottom: 20px;
}

.detail-box.listcheck {
    margin: 30px 0px;
}

#finance-btn {
    width: 250px;
    height: 50px;
    background: #2ccba2;
    border: 0;
    display: inline-block;
    margin-right: 10px;
    color: #fff;
    font-weight: 600;
    font-size: 19px;
    border-radius: 10px;
    margin: 5px;

}

#refer-btn {
    width: 250px;
    height: 50px;
    background: #2ccba2;
    border: 0;
    display: inline-block;
    color: #fff;
    font-weight: 600;
    font-size: 19px;
    border-radius: 10px;
    margin: 5px;
}

.bg_btn {
    padding-top: 10px;
}

.get_strt span {
    font-weight: bold;
}

.checkmark {
    margin-left: 20px;
}

ul.checkmark {
    left: 0;
}

ul.checkmark li {
    background: url(../assets/partners_images/rightw1.png) no-repeat left top;
    background-size: 20px 20px;
    padding-left: 30px;
    line-height: 1.5;
    vertical-align: top;
    padding-bottom: 10px;
}


.hide_desk {
    display: none;
}

/* General Styles */
.fifth.about_section {
    padding: 40px 20px;
}

#hero--buttons {
    justify-content: center;
    gap: 20px;
}

#hero--buttons button {
    padding: 0px;
    font-size: 19px;
    color: #fff;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.checkmark {
    list-style: none;
    padding: 0;
}

.checkmark li {
    font-size: 14px;
    margin-bottom: 10px;
}

/* Desktop Styles */
.desktop-row {
    display: flex;
    gap: 30px;
    margin: 30px 0px;
}

.desktop-row-2 {
    display: flex;
    justify-content: space-between;
}

.detail-box-fifth {
    margin-top: 150px;
}


.about_section .desktop-row .left-column {
    max-width: 50%;
}

.desktop-img {
    max-width: 100%;
    height: auto;
}

.buttons {
    justify-content: center !important;
    gap: 5px !important;
    margin-top: 30px;
}

/* Mobile Styles */
.mobile-row {
    display: block;
    margin-bottom: 30px;
}

.mobile-column {
    margin-bottom: 20px;
}

.mobile-img {
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {

    h2.fourth_h2 {
        font-weight: bold;
        font-size: 3rem;
        padding-bottom: 15px;
    }

    .test {
        display: grid;
        justify-content: center;
        align-items: center;
    }

    section.img_box.about_section {
        padding: 0px;
        margin: 0px;
    }

    /* Mobile View */
    .desktop-row {
        display: none;
    }

    .mobile-row {
        display: block;
    }
}

@media (min-width: 768px) {



    /* Desktop View */
    .mobile-row {
        display: none;
        /* Hide mobile layout */
    }

    .desktop-row {
        display: flex;
        /* Show desktop layout */
    }
}

/*----------------end fifth sect-------- */

/*---------- last sect---------- */
.last {
    background-image: url(../assets/partners_images/11.png);

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 30px;
}

.lasth1 {
    font-family: "Archivo", sans-serif;
}

.last.about_section {
    padding: 40px 0;
    background-color: #f4f4f4;
}

s .content-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.heading h1 {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.description {
    text-align: center;
    font-size: 22px;
    line-height: 1.6;
    color: white;
}

/* Responsive Styling */
@media (min-width: 768px) {
    .content-row {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .heading h1 {
        font-size: 48px;
    }

    .description {
        font-size: 24px;
    }
}


/*----------- end sect------------ */

/* ---------------start cta -------------*/
.cta {
    background-color: #112121;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.cta_btn {
    width: 250px;
    height: 50px;
    background: #2ccba2;
    border: 0;
    display: inline-block;
    color: #fff;
    font-weight: 600;
    font-size: 19px;
    border-radius: 25px;
    margin: 5px;
    text-transform: uppercase;
}

.p2 {
    color: #fff;
    font-size: 20px;
    font-weight: normal;
}

.p2 span {
    font-weight: bold;
}

/*---------- end cta-------- */

/*----------------------------- media queries started -------------------------*/

@media (max-width: 800px) {
    .custom1 {
        margin-top: 20%;
    }


    .thirdsec {
        background-color: #112121 !important;
        height: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mb_h1 {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: visible;
    }

    .logo {
        width: 100px;
        margin-bottom: 40px;
    }

    .third_h1 h2 {
        color: #fff;
    }

    .third_h1 h1 {
        color: #5ae9bd;
        font-weight: bold;
        font-size: 3rem;
        font-family: "Archivo", sans-serif;
    }


    .img_box {
        padding-top: 30%;
    }

    .info_h1 h2 {
        font-weight: 600;

    }

    .info_h1 h1 {
        font-weight: 600;
        font-size: 2.5rem;
        padding-bottom: 10px;
    }

    .info_h1 p {
        font-size: 1rem;

    }

    .hide_desk {
        display: none;
    }

    .lasth1 {
        margin-left: 20px;
    }

    .p1 {
        margin-left: 20px;
    }

}


/*----- media queries medium device----- */
@media (max-width: 600px) {


    .banner {
        padding: 0px;
        background-image: url(../assets/partners_images/15.png);
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .heading h1 {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 2px;
    }

    .last.about_section {
        padding: 0px;
        background-color: #f4f4f4;
    }

    .fifth {
        background-image: url(../assets/partners_images/14.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding: 30px;
    }

    h1.fifth_h1 {
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
    }

    .info {
        background-image: url(../assets/partners_images/16.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 600px;
    }

    h2.fourth_h2 {
        font-weight: bold;
        font-size: 3rem !important;
        padding-bottom: 15px;
    }

    .container.about-section {
        height: 600px;
    }

    .why_us {
        background-image: url(../assets/partners_images/17.png);
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .main {
        padding-bottom: 85%;
        margin-left: 5%;
    }

    .main h1 {
        text-align: left;
        font-size: 6vw;
        word-spacing: 5px;
    }

    .mb_h1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo {
        width: 100px;
        margin-bottom: 50px;
        /* margin-left: 5%; */

    }

    .third_h1 h2 {
        color: #fff;
    }

    .thirdsec .container {
        display: flex;
        flex-direction: column;
        padding: 15px;
    }

    .third_h1 h1 {
        color: #5ae9bd;
        font-weight: bold;
        font-size: 2.5rem;
    }

    .img_box {
        padding-top: 40%;
    }

    .info_h1 h2 {
        font-weight: 600;

    }

    .info_h1 h1 {
        font-weight: 600;
        font-size: 2.5rem;
        padding-bottom: 10px;
    }

    .info_h1 p {
        font-size: 1rem;

    }


    .main p {
        text-align: left;
        color: #fff;
        font-style: normal;
        text-wrap: balance;
        font-weight: bold;
    }

    .hide {
        display: none;
    }

    .hide_desk {
        display: block;
    }

    .l1_img {
        width: 300px;

    }

    .get_strt h2 {
        margin-left: 20px;
    }

    .lasth1 {
        margin-left: 20px;
    }

    .p1 {
        margin-left: 20px;
    }


}

@media (max-width: 600px) {
    h1.third_h1 {

        color: #5ae9bd;
        font-weight: bold;
        font-size: 2rem;
    }
}

@media (max-width: 800px) {
    h1.third_h1 {
        color: #5ae9bd;
        font-weight: bold;
    }
}


@media only screen and (max-width: 344px) and (max-height: 882px) {
    .banner {
        background-image: url(../assets/partners_images/15.png);
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .info {
        background-image: url(../assets/partners_images/16.png);
        height: 650px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 30px;

    }

    .why_us {
        background-image: url(../assets/partners_images/17.png);
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .container.about-sec {
        height: 600px;
    }

    .mb_h1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main {
        padding-bottom: 85%;
        margin-left: 5%;

    }

    .main h1 {
        font-size: 7vw;
        word-spacing: 5px;
    }

    .main p {
        color: #fff;
        font-style: normal;
        text-wrap: balance;
        font-weight: bold;
    }

    .logo {
        width: 100px;
        margin-bottom: 40px;
        /* margin-left: 5%; */

    }

    .third_h1 h2 {
        color: #fff;
    }

    .third_h1 h1 {
        color: #5ae9bd;
        font-weight: bold;
        font-size: 2.5rem;
    }

    .img_box {
        padding-top: 40%;
    }

    .info_h1 {
        padding-bottom: 95%;
    }

    .info_h1 h1 {
        font-weight: 600;
        font-size: 2.5rem;
        padding-bottom: 10px;
    }

    .info_h1 p {
        font-size: 1rem;

    }

    .hide {
        display: none;
    }

    .hide_desk {
        display: block;
    }

    .l1_img {
        width: 300px;
    }

    .get_strt h2 {
        margin-left: 20px;
    }

    .lasth1 {
        margin-left: 20px;
    }

    .p1 {
        margin-left: 20px;
    }
}

.container {
    padding: 0px 30px;
    max-width: 1200px;
    margin: 0 auto;
}

h1.mb_h1 {
    overflow: visible;
    font-weight: 900;
    font-size: 2.2rem;
    padding-bottom: 15px;
    font-family: "Archivo", sans-serif;
    color: #20ac89;
    background-image: linear-gradient(90deg, #20ac89 32%, #016076 70%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.heading_container h1 {
    font-size: 36px;
}

.h1.title1,
.h2,
.p {
    text-align: left;
}

.title1 {
    line-height: 1.2;
}

h2.fourth_h2 {
    font-size: 65px;
}

h2.fifth_h2 {
    font-size: 35px;
}


.main .detail-box p {
    font-size: 18px;
}

.thirdsec .detail-box img {
    max-width: 100%;
}


h1.fifth_h1 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}

.fifth .test h1 {
    font-size: 32px;
}

.test .cta_btn {
    background-color: #28a745;
    color: white;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
}

.test .cta_btn:hover {
    background-color: #218838;
}

/* Modal styling */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

section.actnow {
    background: linear-gradient(90deg, rgba(0, 20, 45, 1) 0%, rgba(0, 39, 44, 1) 31%, rgba(25, 133, 81, 1) 84%);
    padding-top: 12px;
    padding-bottom: 10px;
    border-color: #30ffc8;
    border-style: solid;
    border-width: 3px 0px 3px 0px;
    /* box-shadow: 5px 5px 5px 2px rgb(159 190 218 / 0.4); */
    box-shadow: 5px 5px 5px 2px rgb(0 0 0 / 0.6);
    z-index: 1;
    text-align: center;
}

.acttext {
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 46px;
    word-spacing: 1.2px;
}

/* faq */
.bsb-accordion-6 {
    background-color: #112121;

}

.accordion h1 {
    font-size: 42px;
    font-weight: 400;
    color: white;
    text-align: center;
}


.accordion-item .accordion-button {
    font-family: Open Sans;
    font-weight: 500;
    font-size: 22px;
    background-color: #112121;
    color: white !important;
}


.accordion-button:not(.collapsed) {
    color: white;
    ;
}

.accordion-body {
    background: rgb(15, 55, 85);
    background: linear-gradient(90deg, rgba(15, 55, 85, 1) 0%, rgba(15, 55, 85, 1) 13%, rgba(59, 120, 101, 1) 72%);
    color: white;
}

@media (min-width: 768px) {
    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
}

@media (max-width: 768px) {

    .title1,
    .mb_h1,
    .fourth_h2,
    .info_h1 h1 {
        font-size: 1.8rem;
        line-height: 1.2;
    }

    .acttext {
        font-size: 1.5rem;
    }

    .accordion h1 {
        font-size: 1.8rem;
    }

    .cta_btn,
    .btn1 {
        width: 100%;
        padding: 12px;
    }

    /* Adjust padding and spacing */
    .container {
        padding: 0 15px;
    }

    section {
        padding: 20px 0;
    }

    .info_h1 {
        font-size: 28px;
        font-weight: 600;
        text-align: start;
        line-height: 1.0;
        /* padding: 20px 0; */
    }

    /* Image responsiveness */
    img {
        max-width: 100%;
        height: auto;
    }

    .img_box img {
        margin-bottom: 15px;
    }

    /* Section layout adjustments */
    .banner .row,
    .info .row,
    .thirdsec .row,
    .fifth .desktop-row {
        flex-direction: column;
        align-items: center;
    }

    .banner .main,
    .info .right-column,
    .about_section .row {
        text-align: center;
    }

    .desktop-row {
        display: block;
    }

    /* Button alignment */
    .buttons,
    .cta {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    /* Accordion adjustments */
    .accordion {
        padding: 10px;
    }

    .accordion-item {
        margin-bottom: 10px;
    }

    .accordion-button {
        font-size: 16px;
        padding: 12px;
    }

    /* Footer and Call-to-Action */
    .last.about_section,
    .cta {
        text-align: center;
        padding: 20px;
    }

    /* Hide desktop-specific elements */
    .desktop-row {
        display: none;
    }

    .mobile-row {
        display: flex;
    }
}

/* For very small devices (up to 480px) */
@media (max-width: 480px) {
    .acttext {
        font-size: 1.2rem;
        padding: 10px;
    }

    /* Buttons and inputs */
    .cta_btn,
    .btn1 {
        font-size: 16px;
    }

    /* Adjust banner height */
    .banner {
        height: auto;
        padding: 20px 0;
    }

    /* Adjust image sizes */
    .img1 {
        width: 100%;
        margin-bottom: 15px;
    }

    /* Footer text */
    .description {
        font-size: 20px;
    }

    /* Accordion tweaks */
    .accordion-button {
        font-size: 14px;
    }

    h1.mb_h1 {
        font-weight: 500;
        margin-top: 12px;
        text-align: left;
    }


    .mb {
        text-align: left;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.0;
    }

    /* .font-semibold {
        margin-top: 8px;
        text-align: left;
    } */
}