.song-creation-section {
    background-color: #000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
}

.header {
    display: flex;
    width: 100%;
    max-width: 1325px;
    align-items: center;
    gap: 20px;
    color: #fff;
    white-space: nowrap;
    flex-wrap: wrap;
    justify-content: space-between;
    font: 400 18px Montserrat, sans-serif;
}

@media (max-width: 991px) {
    .header {
        max-width: 100%;
        white-space: initial;
    }
}

.logo {
    aspect-ratio: 1.59;
    object-fit: contain;
    object-position: center;
    width: 145px;
    align-self: stretch;
    max-width: 100%;
}

.nav-links {
    align-self: stretch;
    display: flex;
    gap: 39px;
    flex-wrap: wrap;
    margin: auto 0;
}

@media (max-width: 991px) {
    .nav-links {
        max-width: 100%;
        white-space: initial;
    }
}

.nav-link {
    flex-grow: 1;
}

.cta-button {
    aspect-ratio: 6.1;
    object-fit: contain;
    object-position: center;
    width: 128px;
    align-self: stretch;
    max-width: 100%;
    margin: auto 0;
}

.main-title {
    letter-spacing: 1.6px;
    text-align: center;
    color: #fff;
    font-size: 80px;
    font-weight: 600;
    font-weight: bold;
    background: linear-gradient(90deg, #a6a8a4, #b2e668, #52d6cf, #46cfb2, #398b87);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main-loader-title {
    letter-spacing: 1.6px;
    text-align: center;
    color: #fff;
    font: 600 22px Montserrat, sans-serif;
    font-weight: bold;
    background: linear-gradient(90deg, #a6a8a4, #b2e668, #52d6cf, #46cfb2, #398b87);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.badge {
    /* margin-top: 100px; */
    margin-top: 10px;
    background: linear-gradient(45deg, #b7488c, #43ffeb);
    color: black;
    padding: 10px 15px;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 20px;
    font-weight: bold;
}

.valentines-title{
    background: linear-gradient(90deg, #a6a8a4, #b2e668, #52d6cf, #46cfb2, #398b87);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.homepage-couple-img {
    object-fit: contain;
    height: 550px;
    width: 100%;
    border-radius: 6px;
    opacity: 0.8;
    mask-image: linear-gradient(transparent, rgb(80, 76, 76));
    -webkit-mask-image: linear-gradient(transparent, #b2e06f, #000000, #000000, transparent);
}

.valentine-couple-img {
    object-fit: contain;
    height: 300px;
    width: 100%;
    border-radius: 6px;
    opacity: 0.8;
    mask-image: linear-gradient(transparent, rgb(80, 76, 76));
    -webkit-mask-image: linear-gradient(transparent, #b2e06f, #000000, #000000, transparent);
}

@media (max-width: 991px) {
    .main-title {
        max-width: 100%;
        margin-top: 15px;
        font-size: 45px;
        font-weight: 700;
        letter-spacing: 1.0px;
        line-height: 1.1;
    }

    .badge {
        margin-top: 2px;
        margin-bottom: 0px;
    }

    .homepage-couple-img {
        object-fit: cover;
        height: 500px;
        border-radius: 6px;
        opacity: 0.8;
        mask-image: linear-gradient(transparent, rgb(80, 76, 76));
        -webkit-mask-image: linear-gradient(transparent, #b2e06f, #000000, #000000, transparent);
    }
}

.subtitle {
    color: #fff;
    letter-spacing: 0.72px;
    text-align: center;
    margin-top: 18px;
    width: 723px;
    font-size: 20px;
    font-weight: 400;
}

@media (max-width: 991px) {
    .subtitle {
        max-width: 100%;
        margin-top: 40px;
    }
}

.cta-buttons {
    display: flex;
    margin-top: 35px;
    max-width: 100%;
    gap: 42px;
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    text-align: center;
}

.create-melody-btn,
.sign-in-btn {
    align-self: stretch;
    border-radius: 100px;
    background-color: #000;
    min-height: 70px;
    gap: 20px;
    padding: 18px 32px;
}

.sign-in-btn,
.create-melody-btn {
    border: 2px solid #fff;
}

@media (max-width: 991px) {

    .create-melody-btn,
    .sign-in-btn {
        padding: 0 20px;
    }
}

.hero-image {
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    margin-top: -12px;
    width: 100%;
    color: rgba(255, 255, 255, 0.525);
    white-space: nowrap;
    text-transform: uppercase;
    font: 300 25px Inter, sans-serif;
}

/* General Responsive Tweaks */
@media (max-width: 991px) {
    .hero-image {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        white-space: normal;
    }
}

.hero-image-left,
.hero-image-right {
    aspect-ratio: 1;
    object-fit: contain;
    width: 20%;
    max-width: 100%;
    z-index: 10;
    margin: 0 auto;
}

.hero-image-center {
    background-color: #000;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .hero-image-center {
        padding: 5px;
    }
}

.hero-image-content {
    display: flex;
    width: 100%;
    max-width: 800px;
    flex-direction: column;
    align-items: center;
    padding: 30px 15px;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .hero-image-content {
        padding: 15px;
    }
}

.hero-image-wrapper {
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.hero-image-container {
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
}

.hero-image-overlay {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    padding: 100px 15px;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .hero-image-overlay {
        padding: 50px 10px;
    }
}

.hero-image-bg {
    height: 650px;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.play-button {
    position: relative;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 480px) {
    .play-button {
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
    }
}

.hero-image-right {
    aspect-ratio: 1;
    object-fit: contain;
    width: 20%;
    max-width: 100%;
    margin-top: 20px;
}

@media (max-width: 480px) {

    .hero-image-bg {
        height: 350px;
    }

    .hero-image-left,
    .hero-image-right {
        width: 80%;
        display: none;
        margin: 0;
    }
}

.features-section {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: start;
    /* padding: 90px 20px 80pxs; */
}

@media (max-width: 991px) {
    .features-section {
        max-width: 100%;
        margin-right: 5px;
        padding-top: 10px;
    }
}

.features-container {
    display: flex;
    width: 960px;
    max-width: 100%;
    flex-direction: column;
    justify-content: start;
}

.features-title {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    justify-content: start;
}

@media (max-width: 991px) {
    .features-title {
        max-width: 100%;
    }
}

.features-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
}

@media (max-width: 991px) {
    .features-content {
        max-width: 100%;
    }
}

.features-heading {
    color: #fff;
    font-size: 80px;
    font-weight: 500;
    line-height: 80px;
    letter-spacing: -2.4px;
}

@media (max-width: 991px) {
    .features-heading {
        max-width: 100%;
        font-size: 40px;
        line-height: 44px;
    }
}

.features-description {
    flex: 1;
    align-self: center;
    margin-top: 24px;
    width: 600px;
    max-width: 100%;
    font-size: 18px;
    color: rgba(236, 236, 236, 0.65);
    font-weight: 400;
    line-height: 29px;
}

@media (max-width: 991px) {
    .features-description {
        max-width: 100%;
    }
}

.features-image {
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.002);
    box-shadow: -33px 38px 80px -1px rgba(0, 0, 0, 0.6);
    align-self: center;
    display: flex;
    margin-top: 80px;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    padding: 1px 2px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 991px) {
    .features-image {
        margin-top: 40px;
    }
}

.features-image-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 605px;
    justify-content: center;
    padding: 1px 0;
}

.features-image-bg {
    inset: 0;
    border-radius: 12px;
    height: 75%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    margin-top: 94px;
}

@media (max-width: 991px) {
    .features-image-wrapper {
        max-width: 100%;
        min-height: 400px;
    }

    .features-image-bg {
        margin-top: 25px;
        height: 2%;
        border-radius: 0px;
    }
}



.features-image-content {
    aspect-ratio: 1.8;
    object-fit: contain;
    object-position: center;
    width: 100%;
}

@media (max-width: 991px) {
    .features-image-content {
        max-width: 100%;
    }
}


.flex-bar {
    justify-content: start;
    display: flex;
    align-items: center;
}

.section-title {
    display: flex;
    margin-top: 10px;
    width: 825px;
    max-width: 100%;
    flex-direction: column;
    color: #ececec;
    text-align: center;
    justify-content: start;
    padding: 10px 0;
}

@media (max-width: 991px) {
    .section-title {
        margin-top: 0px;
    }
}

.section-title-text {
    align-self: center;
    max-width: 100%;
    gap: 30px;
    font-size: 68px;
    font-weight: 500;
    /* letter-spacing: -0.32px; */
    line-height: 78px;
    padding: 10px 0;
}

@media (max-width: 991px) {
    .section-title-text {
        max-width: 100%;
        font-size: 40px;
        line-height: 54px;
    }
}

.section-subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    margin-top: 24px;
}

@media (max-width: 991px) {
    .section-subtitle {
        max-width: 100%;
    }
}

.features-grid {
    display: flex;
    margin-top: 80px;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    justify-content: start;
    padding: 10px;
}

@media (max-width: 991px) {
    .features-grid {
        max-width: 100%;
        margin-top: 0px;
    }
}

.features-row {
    display: flex;
    width: 100%;
    align-items: start;
    gap: 24px;
    justify-content: start;
    flex-wrap: wrap;
}

@media (max-width: 991px) {
    .features-row {
        max-width: 100%;
    }
}

.feature-card {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: start;
    flex: 1;
    flex-basis: 0%;
}

@media (max-width: 991px) {
    .feature-card {
        max-width: 100%;
    }
}

.feature-card-wrapper {
    border-radius: 20px;
    box-shadow: 0px -2px 10px rgba(233, 223, 255, 0.3);
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    padding: 60px;
}

@media (max-width: 991px) {
    .feature-card-wrapper {
        max-width: 100%;
        padding: 0 20px;
    }
}

.feature-icon {
    box-shadow: 0px 0px 36px rgba(89, 29, 221, 0.75);
    display: flex;
    min-height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
}

.feature-icon-img {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 50px;
    align-self: stretch;
    margin: auto 0;
}

.feature-content {
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
    color: #ececec;
    font-weight: 500;
    justify-content: start;
}

@media (max-width: 991px) {
    .feature-content {
        max-width: 100%;
    }
}

.feature-title {
    color: #fff;
    font-size: 30px;
    line-height: 1.3;
    letter-spacing: -0.16px;
}

@media (max-width: 991px) {
    .feature-title {
        max-width: 100%;
    }
}

.feature-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-top: 10px;
}

@media (max-width: 991px) {
    .feature-description {
        max-width: 100%;
    }
}

.feature-actions {
    display: flex;
    margin-top: 10px;
    width: 100%;
    padding-top: 16px;
    align-items: start;
    gap: 16px;
    font-size: 16px;
    color: #fff;
    justify-content: start;
}

@media (max-width: 991px) {
    .feature-actions {
        max-width: 100%;
    }
}

.feature-button {
    align-self: stretch;
    gap: 8px;
}

.pricing-section {
    border-radius: 20px;
    box-shadow: 0px -2px 10px rgba(233, 223, 255, 0.3);
    display: flex;
    margin-top: 140px;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    align-items: center;
    color: #ececec;
    text-align: center;
    justify-content: center;
    padding: 60px;
}

@media (max-width: 991px) {
    .pricing-section {
        max-width: 100%;
        margin-top: 40px;
        padding: 0 20px;
    }
}

.pricing-content {
    display: flex;
    width: 768px;
    max-width: 100%;
    flex-direction: column;
    justify-content: start;
}

.pricing-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    align-self: center;
}

@media (max-width: 991px) {
    .pricing-title {
        max-width: 100%;
        font-size: 40px;
    }
}

.pricing-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin-top: 24px;
}

@media (max-width: 991px) {
    .pricing-description {
        max-width: 100%;
    }
}

.pricing-plans {
    display: flex;
    width: 843px;
    max-width: 100%;
    gap: 40px 88px;
    color: #10aca2;
    flex-wrap: wrap;
    margin: 21px 0 0 131px;
    font: 400 24px/1 Inter, sans-serif;
}

.plan-title {
    font-size: 25px;
    line-height: 1.2;
    flex-grow: 1;
    width: 114px;
}

.pricing-table {
    display: flex;
    margin-top: 67px;
    width: 1118px;
    max-width: 100%;
    gap: 20px;
    color: #fff;
    flex-wrap: wrap;
    justify-content: space-between;
    font: 400 14px/1.6 Inter, sans-serif;
}

@media (max-width: 991px) {
    .pricing-table {
        margin-top: 40px;
    }
}

.pricing-features {
    align-self: start;
    display: flex;
    margin-top: 5px;
    flex-direction: column;
    align-items: start;
}

.pricing-feature {
    margin-top: 51px;
}

@media (max-width: 991px) {
    .pricing-feature {
        margin-top: 40px;
    }
}

.pricing-plan {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.plan-feature {
    display: flex;
    gap: 7px;
}

.feature-icon {
    aspect-ratio: 0.84;
    object-fit: contain;
    object-position: center;
    width: 50px;
    margin-right: 12px;
}

.feature-text {
    margin: auto 0;
}

.plan-price {
    font-size: 30px;
    line-height: 1;
    letter-spacing: 0.93px;
    align-self: stretch;
    margin-top: 47px;
}

@media (max-width: 991px) {
    .plan-price {
        margin: 40px 5px 0 0;
    }
}

.plan-cta {
    width: 69px;
    border-radius: 135px;
    background-color: #6fdbd4;
    align-self: stretch;
    margin-top: 56px;
    gap: 8px;
    font-size: 13px;
    color: #000;
    font-weight: 600;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.35px;
    line-height: 1.3;
    padding: 16px 22px 15px 23px;
}

@media (max-width: 991px) {
    .plan-cta {
        margin-top: 40px;
        white-space: initial;
        padding: 0 20px;
    }
}

.footer {
    display: flex;
    margin-top: 110px;
    width: 100%;
    max-width: 1400px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 64px 20px;
}

@media (max-width: 991px) {
    .footer {
        max-width: 100%;
        margin-top: 40px;
    }
}

.footer-container {
    display: flex;
    width: 1200px;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
}

@media (max-width: 991px) {
    .footer-container {
        padding: 0 20px;
    }
}

.footer-columns {
    display: flex;
    width: 100%;
    align-items: start;
    gap: 24px;
    justify-content: start;
    flex-wrap: wrap;
}

@media (max-width: 991px) {
    .footer-columns {
        max-width: 100%;
    }
}

.footer-column {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    color: #fff;
    justify-content: start;
    flex: 1;
    flex-basis: 0%;
}

@media (max-width: 991px) {
    .footer-column {
        max-width: 100%;
    }
}

.footer-links-group {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
}

@media (max-width: 991px) {
    .footer-links-group {
        max-width: 100%;
    }
}

.footer-heading {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.24px;
}

@media (max-width: 991px) {
    .footer-heading {
        max-width: 100%;
    }
}

.footer-links {
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.18px;
    justify-content: start;
}

@media (max-width: 991px) {
    .footer-links {
        max-width: 100%;
    }
}

.footer-link {
    margin-top: 6px;
}

@media (max-width: 991px) {
    .footer-link {
        max-width: 100%;
    }
}

.footer-email-group {
    align-self: start;
    display: flex;
    margin-top: 40px;
    flex-direction: column;
    justify-content: start;
}

.footer-email {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.24px;
}

.footer-email-address {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.18px;
    margin-top: 16px;
}

.footer-copyright {
    color: #939393;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.16px;
    margin-top: 40px;
}

.footer-address-group {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
}

@media (max-width: 991px) {
    .footer-address-group {
        max-width: 100%;
    }
}

.footer-address {
    width: 210px;
    margin-top: 16px;
    max-width: 100%;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.18px;
}

.footer-social-group {
    display: flex;
    margin-top: 40px;
    width: 100%;
    flex-direction: column;
    white-space: nowrap;
    justify-content: start;
}

@media (max-width: 991px) {
    .footer-social-group {
        max-width: 100%;
        white-space: initial;
    }
}

.footer-social-heading {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.24px;
}

.footer-social-links {
    display: flex;
    margin-top: 16px;
    width: 100%;
    flex-direction: column;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.18px;
    justify-content: start;
}

@media (max-width: 991px) {
    .footer-social-links {
        max-width: 100%;
        white-space: initial;
    }
}

.footer-social-link {
    margin-top: 6px;
}

.footer-logo-column {
    align-self: stretch;
    display: flex;
    align-items: end;
    justify-content: end;
    height: 100%;
    width: 180px;
}

.footer-logo {
    aspect-ratio: 1.59;
    object-fit: contain;
    object-position: center;
    width: 146px;
}