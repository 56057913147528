/* Import Tailwind CSS styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Your existing styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, #000000, #0c0812, #000000, #0b0b0b);
  background-size: 400% 400%;
  animation: gradientAnimation 35s ease infinite;
  overflow-x: hidden;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.app {
  display: flex;
  height: 100vh;
  /* background-color: #121212; */
  margin: 50px 40px 0px 40px;
  color: white;
}

/* Sidebar  */

/* .sidebar {
  width: 285px;
  padding: 20px;
  background-color: #151515;
  display: flex;
  border-radius: 14px;
  margin-top: 16px;
  flex-direction: column;
  position: fixed;
  top: 70px;
  left: 45px;
  height: 88vh;
  overflow-y: hidden;
} */

.logo {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.user-info {
  width: 85%;
  margin: 1px;
  background: #171717;
  display: flex;
  align-items: center;
  margin-top: 45px;
  padding: 12px 8px;
  border-radius: 6px;
}

.margin-left130 {
  margin-left: -130px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 10px;
}

.menu {
  list-style: none;
  margin-top: 30px;
  background: #282828;
  padding: 18px 20px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 500;
}

.menu li {
  margin: 20px 0;
}

.new-badge {
  background-color: var(--theme-color);
  padding: 5px;
  font-size: 10px;
  color: white;
  margin-left: 5px;
  border-radius: 4px;
}

.credits {
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;
}

.upgrade-btn {
  background-color: var(--theme-color);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

@media (max-width: 1366px) {
  .credit-balence {
    padding: 10px 8px;
  }

  .avatar {
    width: 30px;
    height: 30px;
  }

  .user-info {
    padding: 10px 8px;
  }
}

@media (max-width: 991px) {
  .user-info {
    margin-top: 30px;
    width: 92%;
    /* padding: 8px 42px; */
  }


}

/* Main Content  */

/* .main-content {
  margin-left: 280px;
  flex-grow: 1;
  padding: 20px;
  height: 100vh;
  padding: 0px 60px;
} */

.featured-product {
  display: flex;
  justify-content: space-between;
}

.product-card {
  width: 45%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  color: white;
}

.hot-badge {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: orange;
  color: black;
  padding: 5px 10px;
  border-radius: 1px 9px 1px 9px;
}

.event-banner {
  padding: 20px;
  background-color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-top: 20px;
}

.common-button {
  background-color: var(--theme-color);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.banner-img {
  width: 100%;
  border-radius: 12px;
}

progress::-webkit-progress-bar {
  background-color: #344e4d;
  /* Background of the entire progress bar */
  border-radius: 10px;
  /* Optional: to match your styling */
}

/* Change the color of the filled progress value */
progress::-webkit-progress-value {
  background-color: #5de6e1;
  /* Custom color for the filled part */
  border-radius: 10px;
  /* Optional: rounded corners */
}

/* For Firefox */
progress {
  color: #5de6e1;
  /* Sets the fill color */
  background-color: #344e4d;
  /* Background of the entire progress bar */
}


@media (max-width: 320px) and (max-height: 460px) {
.about-menu{
  padding: 2px;
}
}