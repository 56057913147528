/* CookieBanner.css */

/* Banner styles */
.cookie-banner {
  border: 1px solid #484848;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #2e2d2d;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  box-sizing: border-box;
}

.cookie-text {
  margin: 0;
  width: 75%;
  word-wrap: break-word;
  text-align: left;
  font-size: 14px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: auto;
}

.cookie-button {
  font-size: 12px;
  margin-left: 10px;
  padding: 4px 0px;
  border: none;
  color: #fff;
  background-color: #000;
  cursor: pointer;
  border: 1px solid #8f8f8f;
  border-radius: 5px;
  min-width: 150px;
}

/* Mobile-responsive styles */
@media (max-width: 768px) {
  .cookie-banner {
    flex-direction: column;
    align-items: center;
    padding: 10px 5px;
  }

  .cookie-text {
    width: 100%;
    text-align: center;
    word-wrap: break-word;
    font-size: 12px;
  }

  .button-container {
    padding: 2px;
    gap: 12px;
    margin: 0;
    font-size: 12px;
  }

  .cookie-button {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
    padding: 10px 0;
    min-width: auto;
  }
}

@media (max-width: 480px) {
  .cookie-button {
    font-size: 12px;
    padding: 2px 45px;
  }

  .cookie-text {
    font-size: 16px;
  }
}