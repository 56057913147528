.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.animated-image {
    filter: invert(1);
    width: 350px;
    height: auto;
    animation: bounce 6s infinite;
}

.coming-soon-title {
    font-style: italic;
    font-weight: 800;
    margin-top: 20px;
    font-size: 3.5rem;
    color: #ffffff;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}