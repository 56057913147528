.middle-banner {
    background: #c3464a;
}

.header-text-color {
    color: #72575e;
}

.secondary-text-color {
    color: #c3464a;
}

.first-patch-bg {
    background: #fef4e5;
}

.patch-border {
    border: 1px solid red;
    border-radius: 12px;
    padding: 10px;
}


.create-song-text {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 24px;
}

@media (max-width: 991px) {
    .create-song-text {
        font-size: 13px;
        padding: 7px 9px;
    }
}


.create-song-bg {
    background: #c4484a;
}

/* Modiji Page Css */

.m-bg-color {
    background: #a5dfe1;
}

.m-videoplayer-bg {
    background: #21b6c7;
}

.font-size-10 {
    font-size: 22px;
}

.font-size-14 {
    font-size: 38px;
    font-weight: 600;
}

@media (max-width: 991px) {
    .font-size-10 {
        font-size: 10px;
    }

    .font-size-14 {
        font-size: 14px;
        font-weight: 600;
    }
}

.bg-green {
    background: #067349;
}

.bg-green-text {
    color: #067349;
}

.border-radius-container {
    background: #fff;
    border: 1px solid #21b6c7;
    border-radius: 12px;
    padding: 10px;
}

.line-height-22 {
    line-height: 23px;
}

.line-height-16 {
    line-height: 26px;
}

.creat-song-text {
    font-size: 24px;
    padding: 10px 24px;
}

@media (max-width: 991px) {
    .creat-song-text {
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 13px;
        padding: 10px 16px;
    }

    .line-height-16 {
        line-height: 16px;
    }

    .parent-pagebanner-height {
        height: 400px;
    }
}

/* Parent Css */

.parent-image-height {
    margin-top: 40px;
    height: 700px;
}

.parent-main-btn {
    font-size: 24px;
    padding: 11px;
    right: 400px;
}


.parent-btn-bg {
    font-size: 24px;
    background: #9fe37c;
}

@media (max-width: 991px) {
    .parent-image-height {
        object-fit: contain;
        height: 200px;
        margin-top: 0px;
    }

    .parent-btn-bg {
        font-size: 12px;
        background: #9fe37c;
    }
}



.blur-border {
    position: relative;
    display: inline-block;
}

.blur-border::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 10px;
    background: linear-gradient(45deg, #000, #444, #222);
    filter: blur(8px);
    transform: scale(1.1);
}

.mobile-view {
    display: none;
    /* Display by default */
}

@media (max-width: 768px) {
    .mobile-view {
        display: block;
    }
}

/* CSS for desktop view */
.desktop-view {
    display: none;
    /* Hide by default */
}

@media (min-width: 1024px) {
    .desktop-view {
        display: block;
        /* Show on screens wider than 1024px */
    }
}

.viral-share-btn{
    color: black;
    border: 1px solid #ad5e60;
    border-radius: 50px;
    padding: 4px 40px;
    display: flex;
}