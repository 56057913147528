.right-side-bg {
   background-color: #fff;
}

.background{
   width: 430px;
   height: 520px;
   position: absolute;
   transform: translate(-50%,-50%);
   left: 50%;
   top: 50%;
}
.background .shape{
   height: 200px;
   width: 200px;
   position: absolute;
   border-radius: 50%;
}
.shape:first-child{
   background: linear-gradient(180deg, #57D0C1 0%, #0D0A30 100%);
   left: 270px;
   top: -10px;
}
.shape:last-child{
   background: linear-gradient(180deg, #57D0C1 0%, #0A1030 100%);
   right: -450px;
   bottom:  -120px;
}



input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}
.z-index-2{
   z-index: 2;
}

@media (max-width: 768px) {
   .background {
     display: none;
   }
 }

 @media (max-width: 2560px) {
   .round-shape {
     position: absolute;
     left: 4%;
     top: 0; /* Avoid "px" for 0 values */
   }

   .round-shape-bottom {
     position: absolute;
     right:14%;
     bottom: 0; /* Ensure the bottom position is explicit if relevant */
   }
 }