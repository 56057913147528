/*  Main Css */

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

:root {
  --theme-color: #3db8b2;
  --button-bg-color: #3db8b2;
  --button-hover-color: #3db8b2;
  --viral-page-text-font: #fef4e5;
}

.theme-color {
  color: var(--button-bg-color);
}

.custom-button {
  /* border-radius: 6px; */
  border: none;
  background: linear-gradient(90deg, #a7e373, #57d0c1, #2c726c);
}

.sidebar-right-border {
  border-right: 1px solid #1a1a1acc;
}

.capture-button {
  /* border-radius: 6px; */
  width: 30%;
  gap: 8px;
  border: none;
  background: linear-gradient(90deg, #a7e373, #57d0c1, #2c726c);
}

.faq-button {
  border: none;
  background: black;
  border: 1px solid white;
}

.button-bg-color {
  border: none;
  background: var(--button-bg-color);
}

.button-bg-color:hover {
  background: var(--button-hover-color);
}

.avatar-bg {
  color: var(--button-hover-color);
}

.Toastify__toast-container {
  margin-top: 50px;
}


.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-28 {
  font-size: 28px;
}

.gap-40 {
  gap: 40px;
}

.gap-80 {
  gap: 80px;
}


.radius-2 {
  border-radius: 2px;
}

.radius-4 {
  border-radius: 4px;
}

.radius-6 {
  border-radius: 6px;
}

.radius-8 {
  border-radius: 8px;
}

.radius-50 {
  border-radius: 50px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.border-left {
  border-left: 1px solid #313131b0;
}

.border-right {
  border-right: 1px solid #313131b0;
}

.btn-border {
  padding: 6px 16px !important;
  border: 1px solid #5c9c99;
  border-radius: 8px;
}

.container-border {
  border-radius: 8px;
}


.height-650 {
  height: auto;
}

.opacity-4 {
  opacity: 0.4;
}

.all-list-height {
  overflow: auto;
  height: 100vh;
}

.middle-component {
  overflow: auto;
  height: 100vh;
}

/* For WebKit browsers (Chrome, Safari, Edge) */
.middle-component::-webkit-scrollbar {
  width: 2px;
}

.middle-component::-webkit-scrollbar-track {
  background: #171717;
}

.middle-component::-webkit-scrollbar-thumb {
  background-color: #171717;
  border-radius: 10px;
}

.middle-component::-webkit-scrollbar-thumb:hover {
  background-color: #171717;
}

/* For Firefox */
.middle-component {
  scrollbar-width: thin;
  scrollbar-color: #5a8482 #171717;
}

/* for all list  */

/* For WebKit browsers (Chrome, Safari, Edge) */
.all-list-height::-webkit-scrollbar {
  width: 2px;
}

.all-list-height::-webkit-scrollbar-track {
  background: #171717;
}

.all-list-height::-webkit-scrollbar-thumb {
  background-color: #171717;
  border-radius: 10px;
}

.all-list-height::-webkit-scrollbar-thumb:hover {
  background-color: #171717;
}

/* For Firefox */
.all-list-height {
  scrollbar-width: thin;
  scrollbar-color: #5a8482 #171717;
}

.position-sticky {
  position: static;
}

.credit-balence {
  padding: 4px 12px;
  border: 1px solid #5eead4;
  border-radius: 5px;
}

.download-btn {
  border: 1px solid #3eb8b2ab;
  border-radius: 6px;
  padding: 2px 8px;
}

.ai-model-fields {
  color: white;
  padding: 2px;
  border: none;
  background: #000000;
  border-radius: 6px;
}

.margin-0 {
  margin: 0px;
}

.margin-top-125 {
  margin-top: 125px;
}

.ai-model-bg {
  background: #50d1cb45;
}

@media (max-width: 1366px) {
  .credit-balence {
    padding: 6px 10px;
  }
}


@media (max-width: 991px) {
  .hidden-sm-screen {
    display: none;
  }

  .credit-balence {
    padding: 14px;
    font-size: 18px;
  }

  .model-margin {
    margin: 12px;
    padding: 2px;
  }

  .navbar-menu-logo {
    margin-top: 8px;
    color: #91de8c;
  }

  .navbar-brand-logo {
    margin-left: 28px;
  }

  .navbar-brand-logo-height {
    height: 38px;
    object-fit: contain;
    width: 100%;
  }

  .margin-top-125 {
    margin-top: 28px;
  }


}

.height-75 {
  filter: invert(1);
  height: 28px;
}

.no-song-generated {
  color: white;
  font-size: 20px;
  justify-content: center;
}

.subscription-model-bg {
  padding: 30px 25px;
  border: 1px solid #27958f;
  background: #000000b5;
}

.get-name {
  margin: 12px;
  color: white !important;
  border: none;
  border-radius: 8px;
  padding: 2px 10px;
  background: #000000;
}

.margin-left-voice {
  margin-right: 12px;
}

.generate-song-height {
  width: 100%;
  object-fit: cover;
  height: 75px;
}

.generate-song-bg {
  display: flex;
  margin: 14px 18px 0 18px;
  justify-content: space-around;
  align-self: center;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #000000;
  background: linear-gradient(45deg, #297b6f, #604353, #395d58, #574554, transparent);
}

.border-6 {
  border-radius: 6px;
}

/* Text for mobile  */
@media (max-width: 991px) {
  .heading-text-mobile {
    font-size: 12px;
  }

  .no-song-generated {
    font-size: 16px;
  }

}

.search-bar {
  background: #000000;
  padding: 6px 18px;
  color: #fff;
  border-radius: 6px;
}

@media (max-width: 991px) {
  .margin-left-voice {
    margin-right: 4px;
    margin-left: 16px;
  }

  .generate-song-bg {
    margin: 0;
    width: 100%;
    display: flex;
    padding: 0px 30px;
  }

  .generate-song-height {
    height: 45px;
    width: 100%;
  }


  .generated-card {
    justify-content: center;
    margin: 20px 14px 16px 14px;
  }

  .middle-component {
    padding: 0 14px;
    height: auto;
    overflow-y: hidden;
  }

  .visible-none-mobile {
    display: none;
  }

  .container-border {
    background: #171717;
    padding: 8px 0px;
    border-radius: 8px;
  }

  .search-bar {
    padding: 6px 0px 6px 6px;
  }
}

.display-flex-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-mg-12 {
  margin-bottom: 8px;
}

.song {
  border-radius: 6px;
  background: linear-gradient(45deg, #144c49, #1f2a38, #1c2129, transparent);
}

.search-bar:focus {
  outline: none;
}

.music-player {
  height: 60px;
  background: linear-gradient(45deg, #1b3b41, #2e4043, #23322c, #1b222f, transparent);
}

@media (max-width: 991px) {
  .music-player {
    margin-bottom: 50px;
  }
}

.audio-player {
  height: 60px;
  background: transparent;
}

.dropdown-padding {
  padding: 2px 10px;
}

.border-teal {
  border: 1px solid #4dbbaf6b;
}

.coming-soon-badge {
  font-size: 12px;
  background: green;
  border-radius: 8px;
  padding: 0 6px;
}

.border-radius-2 {
  border-radius: 8px;
  border: 1px solid #919191;
}

.divider {
  border: 1px solid #3b3b3b;
  margin: 32px 40px;
}

.delete-popup {
  background: #000;
  border: 1px solid #313131;
}

.logout-popup {
  background: #000;
  border: 1px solid #7b7b7b;
}

.update-popup {
  background: white;
}

.dashboard-banner-height {
  margin-top: 100px;
  border-radius: 10px;
  height: 400px;
  width: 50%;
}

@media (max-width: 991px) {
  .dashboard-banner-height {
    margin-top: 30px;
    border-radius: 0px;
    height: 200px;
    width: 100%;
  }
}

.side-bar-profile {
  background: #171717;
  padding: 5px 10px;
  border: 1px solid #393939;
  border-radius: 6px;
}

.video-border {
  border: 1px solid #47aca11c;
  height: 400px;
}

.ceo-banner-bg {
  background: linear-gradient(45deg, #2f4a3f, #000000, #234d4a, transparent);
}

.custom-popup {
  color: white !important;
}

.custom-popup .ant-picker-body {
  background-color: #1f2937 !important;
  color: white !important;
}

.custom-popup .ant-picker-suffix {
  color: white !important;
}

.custom-popup .ant-picker-cell-inner {
  background-color: #1f2937 !important;
  color: white !important;
}

.custom-popup .ant-picker-header {
  background-color: #1f2937 !important;
  color: white !important;
}

.custom-popup .ant-picker-header-view {
  background-color: #1f2937 !important;
  color: white !important;
}

.custom-popup .ant-picker-cell:hover {
  background-color: #333 !important;
}

.custom-popup .ant-picker-today-btn {
  background-color: #1f2937 !important;
  color: white !important;
}

.custom-popup .ant-picker-footer {
  background-color: #1f2937 !important;
  background-color: #1f2937 !important;
}

.custom-popup .ant-picker-header>button {
  background-color: #1f2937 !important;
  color: white !important;
}

.custom-popup .ant-picker-content th {
  background-color: 1f2937 !important;
  color: white !important;
  font-weight: normal !important;
  border: none !important;
}


.contact-img-height {
  height: 900px;
}


.free-song-banner {
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 8px;
}

.captch-style {
  padding: 0 5px;
  border-radius: 6px;
  height: 38px;
  width: 100px;
  color: #fff;
  user-select: none;
  pointer-events: none;
  background: #1f29378c;
  border: 1px solid #47505d;
}

.showcase-card {
  background: linear-gradient(45deg, #000000, #050d0c, #161616, transparent);
  border-radius: 6px;
  border-bottom: 1px solid #45a99e59;
  border-right: 1px solid #96ad9e61;
}

.rounded-button {
  padding: 2px 10px;
  border-radius: 6px;
  border: 1px solid #313131;
  background: #000000;
  margin: 0 13px;
}


@media (max-width: 991px) {
  .free-song-banner {
    margin-bottom: 22px;
  }

  .contact-img-height {
    height: 800px;
  }

  .small-model-margin {
    margin: 20px;
    display: contents;
  }

}

.view-plans-btn {
  padding: 1px 10px;
  border-radius: 4px;
  font-size: 14px;
  background: #5cab9b;
  color: #000;
}

.mobile-break {
  display: none;
}

@media (max-width: 768px) {
  .mobile-break {
    display: block;
  }
}

.small-model-margin {
  margin: 20px;
}

.bottom-sheet {
  border-top: 1px solid #4b4b4b;
  z-index: 999;
  background: linear-gradient(45deg, #000000, #1b1a1a, #000000);
}

.bottom-sheet-btn {
  background: #32837f;
}

/* footter only for mobile  */

@media (max-width: 991px) {
  .footer-bottom-margin {
    margin-bottom: 36px;
  }

  .logout-sm-button {
    width: 92%;
    height: 40px;
    border: 1px solid #393939de;
    border-radius: 6px;
    background: #171717;
    padding: 0px 8px;
    margin-right: 23px;
  }

  .credit-shown-btn {
    width: 80%;
    border-radius: 5px;
    background: #000000;
  }
}

@media only screen and (max-width: 768px) and (pointer: coarse) {
  .iphone-mb-40 {
    margin-bottom: 40px;
  }
}

@media (max-width: 1366px) and (min-width: 992px) {
  .createSong {
    height: 100vh;
  }
}

@media (max-width: 991px) {
  .createSong {
    height: auto;
  }
}

@media (max-width: 1366px) {
  .customAi {
    height: 91%;
  }
}

.profile {
  height: 93vh;
  overflow: auto;
}

@media (max-width: 1366px) {
  .profile {
    height: 91vh;
  }
}

@media (max-width: 991px) {
  .profile {
    height: 89%;
  }
}

@media (max-width: 991px) {
  .country-input {
    margin-left: 0 !important;
  }
}

.home {
  height: 100vh;
}

.homeCards {
  height: 93%;
  overflow: auto;
}

@media (max-width: 1366px) {
  .homeCards {
    height: 91%;
  }
}

@media (max-width: 991px) {
  .homeCards {
    height: 89%;
  }
}

/* @media (max-width: 991px){
  .audio-cloning {
    height: 89%;
    overflow: auto;
  }
} */

.clone-component {
  overflow: auto;
  /* height: 100vh; */
}

/* For WebKit browsers (Chrome, Safari, Edge) */
.clone-component::-webkit-scrollbar {
  width: 2px;
}

.clone-component::-webkit-scrollbar-track {
  background: #171717;
}

.clone-component::-webkit-scrollbar-thumb {
  background-color: #171717;
  border-radius: 10px;
}

.clone-component::-webkit-scrollbar-thumb:hover {
  background-color: #171717;
}

/* For Firefox */
.clone-component {
  scrollbar-width: thin;
  scrollbar-color: #5a8482 #171717;
}

.videocomponent-overflow-y {
  overflow-y: auto;
}

@media (max-width: 991px) {
  .clone-component {
    padding: 10px;
    height: auto;
    overflow-y: hidden;
  }

  .videocomponent-overflow-y {
    overflow-y: auto;
  }
}

.song-model-border {
  border: 1px solid #515151;
  border-radius: 6px;
}

@media (max-width: 1366px) and (min-width: 992px) {
  .clone-component {
    padding: 14px;
    height: 89vh;
  }
}

.reset-button {
  top: -58.5%;
  left: 37.5%;
  opacity: 0.5;
  border: 1px solid;
  padding: 0 10px;

}

.reset-button:hover {
  background-color: grey;
  color: white;
  opacity: 0.8;
}

.image-height {
  height: 130px;
  opacity: 0.9;
}

/* tabs for library */

.tab-button {
  padding: 6px;
  font-size: 16px;
  cursor: pointer;
  background: none;
  transition: color 0.3s;
  font-weight: 600;
}

.tab-button.active-tab {
  font-weight: bold;
  color: white;
  border-bottom: 2px solid teal;
}


.video-song-container {
  /* padding: 20px; */
  text-align: center;
  color: #ccc;
}

.custom-dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  padding-right: 30px;
  background: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"%3E%3Cpath d="M7 10l5 5 5-5H7z"/%3E%3C/svg%3E') no-repeat right center;
  background-color: rgb(55 65 81);
  background-size: 20px;
  background-position-x: calc(100% - 10px);
}

.noDataMessage {
  color: gray;
  margin: 20px 0;
}

.subscribe-btn {
  font-weight: 600;
  font-size: 12px;
  padding: 8px 12px;
  color: #000;
  background: linear-gradient(90deg, #a7e373, #57d0c1, #2c726c);
  border: none;
  border-radius: 50px;
}

.mobile-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile-only {
    display: block;
  }
}

.generate-collage-video {
  width: 60%;
  border-radius: 26px;
  border: 1px solid #262626;
  background: linear-gradient(45deg, #161616, #1c1c1c, #585858, transparent);
}

@media (max-width: 991px) {
  .generate-collage-video {
    width: 90%;
  }
}

.home-title-gradient {
  color: #fff;
  background: linear-gradient(90deg, #72b8af, #96ff00, #42eae1, #39a790, #0fbbb2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-blue-500 {
  border: 4px solid #4db9ad;
}

.bg-gray-800 {
  background-color: #1f2937;
  color: white;
}

.showcase-card {
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.aboutus-margin-36 {
  margin-top: 9rem;
}


@media only screen and (max-width: 375px) and (max-height: 667px) {
  .aboutus-margin-36 {
    margin-top: 3.2rem;
  }

  .top-28 {
    top: 70px;
  }
}

.valentines-gradient {
  background: linear-gradient(0deg, black, black, #3c2329, transparent);
}

.valentines-share-btn {
  border: 1px solid #5c5c5c;
  padding: 4px 20px;
  border-radius: 50px;
}

.limited-order {
  border-top: 4px solid #ed29593d;
  font-family: Centaur;
  border-bottom: 4px solid #ed29593d;
}

.strock-text {
  color: #ed6084a1;
  text-shadow: -1px -1px 0 #db2389ed, 1px -1px 0 #f7a9d9fc, -1px 1px 0 #a73a7c, 1px 1px 0 #ed6363
    /* text-shadow: -1px -1px 0 #393939a3, 1px -1px 0 #727272, -1px 1px 0 #000000, 1px 1px 0 #6e6e6e; */
}

.strock-text-sub {
  color: #e03a83;
}

.valentines-share-btn-pink {
  font-size: 16px;
  color: #22c55d;
  background: #efe6bf;
  border: 1px solid #ffffff;
}

.custom-bg-valentines {
  background: linear-gradient(45deg, #a95f98, #9f6ea0, #78abbf, #4eefe3, transparent);
}

.popup {
  z-index: 9999;
  position: fixed;
}

.popup-bg {
  border-radius: 18px 18px 0px 18px;
  border: 2px solid #fbdbe5;
  background: white;
}

.line-leading {
  line-height: 1.2;
}