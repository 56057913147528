.body {
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/partners_images/17.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.thankyou {
    padding: 140px 20px 140px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    text-align: center;
    /* background-image: url(../assets/partners_images/17.png); */
    background-color: #112121;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.imgclass {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.imgclass img {
    width: 20%;
}

.img {
    text-align: center;
    margin: 10px 0;
}

.content {
    margin-top: -15px;
    color: white;
}
h1.thanks-class {
    font-weight: 600;
    font-size: 50px;
}

@media (max-width: 767px) {

    h1.thanks-class {
        font-size: 50px;
    }

    .thankyou {
        padding: 20px;
    }

    .h1 {
        font-size: 32px;
    }

    .h3 {
        font-size: 20px;
        margin-top: -10px;
    }

    .imgclass img {
        width: 80%;
    }
}


@media (max-width: 600px) {
    h1.thanks-class {
        font-size: 30px;
    }

    .thankyou {
        padding: 20px;
    }

    .h1 {
        font-size: 22px;
    }

    .h3 {
        font-size: 20px;
        margin-top: -10px;
    }

    .imgclass img {
        width: 50%;
    }
}